import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';

import { I18NextService } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { appService } from 'src/app/services/appService';
import { SlideInTopAnimation, Collapse } from 'src/assets/styles/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [ SlideInTopAnimation, Collapse ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public pageData: any;

  public showHelp:boolean = false;
  public hoverActive:boolean = false;
  public showPricing:boolean = false;
  public showToast:boolean = true;
  public showHeader:boolean = true;
  public iStoreUrl: string = '';
  public queryParams: any;
  public page: number = 1;

  public showMobile: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private appService: appService,
    private i18NextService: I18NextService,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) { }

  public ngOnInit() {
    
    this.showMobile = window.innerWidth < 992;
    this.iStoreUrl += this.appService.rpath;

    this.subscriptions.push(this.appService.showHeader.subscribe((response: any) => {
      this.showHeader = response;
    }))

    this.subscriptions.push(this.route.queryParams.subscribe((response) => { 
      if (response) {
        this.queryParams = response;
      }
    }));

    this.subscriptions.push(this.appService.page.subscribe((resp) => {
      this.page = resp;
    }));

    this.setToast();
    this.updateContent();
  }

  @HostListener('window:resize', ['$event'])  
  onResize() {  
    this.showMobile = window.innerWidth < 992;
  }  

  public ngAfterViewInit() {
    this.showHeader = true;
  }

  public updateContent() {
    this.pageData = this.i18NextService.t('translations:i18_dissExpress');
  }

  public iStoreURL() {
    let queryString = Object.entries(this.queryParams)
        .map(entry => entry.join('='))
        .join('&');
    if (this.page > 1) {
      queryString += `&page=${this.page}`;
    }
    return environment.iStoreUrl + this.appService.rpath + encodeURIComponent(`?${queryString}`);
  }

  public toggleHelp() {
    if (this.showHelp) {
      this.showHelp = false;
      this.showPricing = false;
    } else {
      this.setShowHelp(true);
    }
    this.hoverActive = false;
  }

  public setShowHelp(override: boolean) {
    if (override) {
      this.showHelp = true;
      this.showPricing = false;
    }
  }

  public async setHideHelp (override: boolean) {
    if (override) {
      await this.delay(300);
      if (!this.hoverActive) {
        this.showHelp = false;
        this.hoverActive = false;
      }
    }
  }

  public togglePricing() {
    if (this.showPricing) {
      this.showPricing = false;
      this.showHelp = false;
    } else {
      this.setShowPricing(true);
    }
    this.hoverActive = false;
  }

  public setShowPricing(override: boolean) {
    if (override) {
      this.showPricing = true;
      this.showHelp = false;
    }
  }

  public async setHidePricing(override: boolean) {
    if (override) {
      await this.delay(300);
      if (!this.hoverActive) {
        this.showPricing = false;
        this.hoverActive = false;
      }
    }
  }

  public async setToast() {
    const toastCookie = this.cookieService.get('toastAck');
    if (toastCookie) {
      this.showToast = false;
    }
    else {
      await this.delay(5000);
      this.showToast = false;
    }
  }

  public hideToast() {
    this.showToast = false;
    this.cookieService.set('toastAck', 'true')
  }

  public jumpToTop() {
    document.querySelector('#header')?.scrollIntoView();
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
